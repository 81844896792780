<template>
  <AppModalStandardWrapper width="820px">
    <div ref="captchaForm" />
  </AppModalStandardWrapper>
</template>

<script setup lang="ts">
import { nextTick, onMounted, useTemplateRef } from 'vue'

import AppModalStandardWrapper from '../AppModalStandardWrapper.vue'

const emit = defineEmits<{
  onSuccess: [value: string]
  onLoad: []
  onError: []
  onPuzzleTimeout: []
  onPuzzleIncorrect: []
  onPuzzleCorrect: []
  close: []
}>()

/* ------------------------------------------------------------------------------------------------------------------ */

const captchaForm = useTemplateRef('captchaForm')

function loadScript() {
  return new Promise((resolve, reject) => {
    if (document.getElementById('AwsWAFScript')) {
      resolve(undefined)

      return
    }

    const AwsWafScript = document.createElement('script')

    AwsWafScript.id = 'AwsWAFScript'
    AwsWafScript.async = true
    AwsWafScript.src = import.meta.env.PUBLIC_AWS_WAF_JSAPI_URL

    AwsWafScript.onload = () => {
      resolve(undefined)
    }

    AwsWafScript.onerror = () => {
      reject()
    }

    document.head.appendChild(AwsWafScript)
  })
}

onMounted(async () => {
  await nextTick()
  await loadScript()

  if (!captchaForm.value) {
    emit('close')

    return
  }

  window.AwsWafCaptcha.renderCaptcha(captchaForm.value, {
    onSuccess: (wafToken: string) => {
      emit('close')
      emit('onSuccess', wafToken)
    },
    onLoad: () => {
      emit('onLoad')
    },
    onError: () => emit('onError'),
    onPuzzleTimeout: () => emit('onPuzzleTimeout'),
    onPuzzleIncorrect: () => emit('onPuzzleIncorrect'),
    onPuzzleCorrect: () => emit('onPuzzleCorrect'),

    apiKey: import.meta.env.PUBLIC_AWS_WAF_APIKEY
  })
})
</script>
